import { PageProps } from "gatsby";
import React from "react";

import { RhythmNotation } from "../../components/pages/RhythmNotation";

import { dnProps } from "../../js/utils";

const RhythmNotationPage = (props: PageProps) => (
  <RhythmNotation {...{ ...dnProps, ...props }} />
);

export default RhythmNotationPage;
